import "./CustomInstallerDistanceReportPage.css";
import { useState } from "react";
import client from "../../client";
import {
  Alert,
  Button,
  Container,
  Slide,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { InstallerReportWorkBook } from "../../interfaces/InstallerReportWorkBook";
import InstallerReportWorkBookSelector from "../../components/InstallerReportWorkBookSelector/InstallerReportWorkBookSelector";

const CustomInstallerDistanceReportPage = () => {
  const [marketplaceStoreDetails, setMarketplaceStoreDetails] =
    useState<InstallerReportWorkBook>({});
  const [selectedInstallersDetails, setSelectedInstallersDetails] =
    useState<InstallerReportWorkBook>({});
  const [
    reportRequestSubmittedSuccessfullyDialog,
    setReportRequestSubmittedSuccessfullyAlert,
  ] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const onMarketplaceStoreDetailsChange = (
    installerReportWorkBook: InstallerReportWorkBook
  ) => {
    setMarketplaceStoreDetails(installerReportWorkBook);
  };
  const onSelectedInstallersDetailsChange = (
    installerReportWorkBook: InstallerReportWorkBook
  ) => {
    setSelectedInstallersDetails(installerReportWorkBook);
  };

  const onEmailChange = (event: any) => {
    const email = event.target.value;
    if (!!email) {
      setEmail(email);
    }
  };

  const onSubmit = () => {
    if (
      !!marketplaceStoreDetails &&
      !!selectedInstallersDetails &&
      !!marketplaceStoreDetails.uploadedFile &&
      !!marketplaceStoreDetails.fileName &&
      !!selectedInstallersDetails.uploadedFile &&
      !!selectedInstallersDetails.fileName &&
      !!marketplaceStoreDetails.selectedWorkSheetName &&
      !!marketplaceStoreDetails.selectedWorkSheetIndex &&
      !!selectedInstallersDetails.selectedWorkSheetName &&
      !!selectedInstallersDetails.selectedWorkSheetIndex
    )
      client.axisFunctions
        .createMarketplaceInstallerCountReportCustomInstallers(
          "marketplaceStoresFile",
          marketplaceStoreDetails.uploadedFile,
          marketplaceStoreDetails.fileName,
          "selectedInstallerFile",
          selectedInstallersDetails.uploadedFile,
          selectedInstallersDetails.fileName,
          marketplaceStoreDetails.selectedWorkSheetName,
          marketplaceStoreDetails.selectedWorkSheetIndex,
          selectedInstallersDetails.selectedWorkSheetName,
          selectedInstallersDetails.selectedWorkSheetIndex,
          email,
          [5, 10, 25]
        )
        .then(() => {
          setReportRequestSubmittedSuccessfullyAlert(true);
        });
  };

  return (
    <div className="CustomInstallerDistanceReportPage">
      <br />
      <Typography variant="h3" gutterBottom>
        Installer Distance Report - Custom
      </Typography>
      <br />
      <br />
      <Container maxWidth="sm">
        <Stack spacing={5}>
          <Stack direction="row" spacing={5}>
            <InstallerReportWorkBookSelector
              installerReportWorkBook={marketplaceStoreDetails}
              onInstallerReportWorkBookChange={onMarketplaceStoreDetailsChange}
            />
            <InstallerReportWorkBookSelector
              installerReportWorkBook={selectedInstallersDetails}
              onInstallerReportWorkBookChange={
                onSelectedInstallersDetailsChange
              }
            />
          </Stack>
          <TextField
            value={email}
            onChange={onEmailChange}
            label="Enter your email"
          />
          <Button variant="contained" onClick={onSubmit}>
            Submit
          </Button>
        </Stack>
      </Container>
      <Snackbar
        open={reportRequestSubmittedSuccessfullyDialog}
        onClose={() => setReportRequestSubmittedSuccessfullyAlert(false)}
        autoHideDuration={3000}
        TransitionComponent={TransitionRight}
      >
        <Alert severity="success">Report Request Submitted Successfully</Alert>
      </Snackbar>
    </div>
  );
};

const TransitionRight = (props: any) => {
  return <Slide {...props} direction="right" />;
};

export default CustomInstallerDistanceReportPage;
