import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import {REQUIRES_AUTH} from "../../constants/auth.constants";

console.log("auth: ",process.env)
function AuthGate({ children }: { children: React.ReactNode }) {
    return REQUIRES_AUTH ? (
        <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
    ) : (
        <UnauthenticatedTemplate>{children}</UnauthenticatedTemplate>
    );
}

export default AuthGate;
