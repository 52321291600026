import {AXIS_TOKEN_REQUEST} from "../../constants/auth.constants";
import {getMsalAccount} from "../../components/AuthGate";
import {msalInstance} from "../../index";

export default interface Authenticator {
    authHeader(): string | null;
    authenticate(): Promise<string | null>;
}

type MsalAuthenticatorProps =
    | typeof AXIS_TOKEN_REQUEST;

export const getMsalAuthenticator = (
    props: MsalAuthenticatorProps
): Authenticator => ({
    authHeader: () => "Authorization",
    authenticate: async (): Promise<string> => {
        await getMsalAccount();
        const [account] = msalInstance.getAllAccounts();
        if (!account) {
            throw Error(
                "No active account! Verify a user has been signed in and setActiveAccount has been called."
            );
        }

        const response = await msalInstance.acquireTokenSilent({
            ...props,
            account,
        });

        return Promise.resolve(`Bearer ${response.accessToken}`);
    },
});
