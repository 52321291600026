import type { Configuration } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const MSAL_CONFIG: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID ?? "",
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MSAL_TENANT_ID}`,
        redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI ?? "",
        postLogoutRedirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI ?? "",
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
    },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const LOGIN_REQUEST = {
    scopes: ["User.Read"],
};

export const REQUIRES_AUTH = process.env.REACT_APP_REQUIRES_AUTH === "true";

// API related scopes for Axis. By default, the scopes below are included in the web API's access token.
export const AXIS_TOKEN_REQUEST = {
    scopes: [`${process.env.REACT_APP_MSAL_CLIENT_ID}/.default`],
};
/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const GRAPH_CONFIG = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
