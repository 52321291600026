import { EventType, PublicClientApplication } from "@azure/msal-browser";


import AuthGate from "./AuthGate";

import type { EventMessage } from "@azure/msal-browser";
import {MSAL_CONFIG} from "../../constants/auth.constants";

export const msalInstance = new PublicClientApplication(MSAL_CONFIG);
export default AuthGate;

export const getMsalAccount = async () => {
    await msalInstance.initialize();

    const [account] = msalInstance.getAllAccounts();
    if (account) {
        msalInstance.setActiveAccount(account);
    }

    msalInstance.addEventCallback((event: EventMessage) => {
        if (
            event.eventType === EventType.LOGIN_SUCCESS &&
            event.payload &&
            "account" in event.payload
        ) {
            if (event.payload?.account) {
                msalInstance.setActiveAccount(event.payload.account);
            }
        }
    });
};
