import "./AllInstallerDistanceReportPage.css";
import { useState } from "react";
import client from "../../client";
import {
  Alert,
  Button,
  Container,
  Slide,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { InstallerReportWorkBook } from "../../interfaces/InstallerReportWorkBook";
import InstallerReportWorkBookSelector from "../../components/InstallerReportWorkBookSelector/InstallerReportWorkBookSelector";

const AllInstallerDistanceReportPage = () => {
  const [marketplaceStoreDetails, setMarketplaceStoreDetails] =
    useState<InstallerReportWorkBook>({});
  const [
    reportRequestSubmittedSuccessfullyDialog,
    setReportRequestSubmittedSuccessfullyAlert,
  ] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const onMarketplaceStoreDetailsChange = (
    installerReportWorkBook: InstallerReportWorkBook
  ) => {
    setMarketplaceStoreDetails(installerReportWorkBook);
  };

  const onEmailChange = (event: any) => {
    const email = event.target.value;
    if (!!email) {
      setEmail(email);
    }
  };

  const onSubmit = () => {
    console.log(`onSubmit() called`);
    if (
      !!marketplaceStoreDetails &&
      !!marketplaceStoreDetails.uploadedFile &&
      !!marketplaceStoreDetails.fileName &&
      !!marketplaceStoreDetails.selectedWorkSheetName &&
      !!marketplaceStoreDetails.selectedWorkSheetIndex
    )
      client.axisFunctions
        .createMarketplaceInstallerCountReportAllInstallers(
          "marketplaceStoresFile",
          marketplaceStoreDetails.uploadedFile,
          marketplaceStoreDetails.fileName,
          marketplaceStoreDetails.selectedWorkSheetName,
          marketplaceStoreDetails.selectedWorkSheetIndex,
          email,
          [5, 10, 25]
        )
        .then(() => {
          setReportRequestSubmittedSuccessfullyAlert(true);
        });
  };

  return (
    <div className="AllInstallerDistanceReportPage">
      <br />
      <Typography variant="h3" gutterBottom>
        Installer Distance Report - All
      </Typography>
      <br />
      <br />
      <Container maxWidth="sm">
        <Stack spacing={2}>
          <InstallerReportWorkBookSelector
            installerReportWorkBook={marketplaceStoreDetails}
            onInstallerReportWorkBookChange={onMarketplaceStoreDetailsChange}
          />
          <TextField
            value={email}
            onChange={onEmailChange}
            label="Enter your email"
          />
          <Button variant="contained" onClick={onSubmit}>
            Submit
          </Button>
        </Stack>
      </Container>
      <Snackbar
        open={reportRequestSubmittedSuccessfullyDialog}
        onClose={() => setReportRequestSubmittedSuccessfullyAlert(false)}
        autoHideDuration={3000}
        TransitionComponent={TransitionRight}
      >
        <Alert severity="success">Report Request Submitted Successfully</Alert>
      </Snackbar>
    </div>
  );
};

const TransitionRight = (props: any) => {
  return <Slide {...props} direction="right" />;
};

export default AllInstallerDistanceReportPage;
