import "./FeatureFlagsPage.css";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import * as React from "react";
import {useEffect, useState} from "react";
import Switch from "@mui/material/Switch";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Slide,
    Snackbar,
    TextField
} from "@mui/material";
import client from "../../client";

export interface FeatureFlag {
    key: string,
    value: boolean
}

const FeatureFlagsPage = () => {
    const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>([]);
    const [isNewFeatureFlagDialogOpen, setIsNewFeatureFlagDialogOpen] = useState(false);
    const [editFeatureFlagAlert, setEditFeatureFlagAlert] = useState<boolean>(false);
    const [createFeatureFlagAlert, setCreateFeatureFlagAlert] = useState<boolean>(false);
    const [deleteFeatureFlagAlert, setDeleteFeatureFlagAlert] = useState<boolean>(false);

    const fetchFeatureFlags = () => {
        client.utilFunctions.fetchFeatureFlags().then(featureFlags => {
            if (featureFlags) {
                setFeatureFlags(featureFlags)
            }
        })
    }

    useEffect(() => {
        fetchFeatureFlags();
    }, []);

    const toggleFeatureFlag = (key: string, newValue: boolean) => {
        if (window.confirm(`Are you sure you want to change feature flag ${key} to ${newValue}`)) {
          console.log(`${key} changed to ${newValue}`);
          client.utilFunctions.toggleFeatureFlag(key, newValue)
              .then(featureFlags => {
                  if (featureFlags) {
                      setFeatureFlags(featureFlags)
                      setEditFeatureFlagAlert(true);
                  }
              })
        }
    }

    const toggleFeatureFlagDialog = (newValue: boolean) => setIsNewFeatureFlagDialogOpen(newValue);
    const openFeatureFlagDialog = () => toggleFeatureFlagDialog(true);
    const closeFeatureFlagDialog = () => toggleFeatureFlagDialog(false);

    function createFeatureFlag(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries((formData as any).entries());
        const featureFlagName = formJson.featureFlagName;

        client.utilFunctions.createFeatureFlag(featureFlagName)
            .then(featureFlags => {
                if (featureFlags) {
                    setFeatureFlags(featureFlags)
                    setCreateFeatureFlagAlert(true);
                }
            })

        closeFeatureFlagDialog();
    }
    const deleteFeatureFlag = (key: string, value: boolean) => () => {
        if (window.confirm(`Are you sure you want to delete feature flag ${key}`)) {
            client.utilFunctions.deleteFeatureFlag(key, value)
                .then(featureFlags => {
                    if (featureFlags) {
                        setFeatureFlags(featureFlags)
                        setDeleteFeatureFlagAlert(true);
                    }
                })
        }
    }

    return (
        <div className="FeatureFlagsPage">
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Feature Flag Name</b></TableCell>
                            <TableCell><b>Status</b></TableCell>
                            <TableCell><b>Action</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {featureFlags.map((featureFlag: FeatureFlag) => (
                            <TableRow
                                key={featureFlag.key}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {featureFlag.key}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Switch
                                        checked={featureFlag.value}
                                        onChange={() => toggleFeatureFlag(featureFlag.key, !featureFlag.value)}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                      variant="outlined"
                                      size="small"
                                      onClick={deleteFeatureFlag(featureFlag.key, featureFlag.value)}>
                                      delete
                                  </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button
                style={{marginTop: '50px'}}
                variant="contained"
                size="medium"
                onClick={openFeatureFlagDialog}>
                Create new Feature Flag
            </Button>
            <Dialog
                open={isNewFeatureFlagDialogOpen}
                onClose={closeFeatureFlagDialog}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        createFeatureFlag(event);
                    },
                }}
            >
                <DialogTitle>Create New Feature Flag</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter the new feature flag name
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="featureFlagName"
                        name="featureFlagName"
                        label="Feature Flag Name"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeFeatureFlagDialog}>Cancel</Button>
                    <Button variant="contained" type="submit">Create</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={editFeatureFlagAlert}
                onClose={() => setEditFeatureFlagAlert(false)}
                autoHideDuration={3000}
                TransitionComponent={TransitionRight}
            >
                <Alert severity="success">Feature Flag update successfully</Alert>
            </Snackbar>
            <Snackbar
                open={createFeatureFlagAlert}
                onClose={() => setCreateFeatureFlagAlert(false)}
                autoHideDuration={3000}
                TransitionComponent={TransitionRight}
            >
                <Alert severity="success">Feature Flag created successfully</Alert>
            </Snackbar>
            <Snackbar
                open={deleteFeatureFlagAlert}
                onClose={() => setDeleteFeatureFlagAlert(false)}
                autoHideDuration={3000}
                TransitionComponent={TransitionRight}
            >
                <Alert severity="success">FeatureFlag deleted successfully</Alert>
            </Snackbar>
        </div>
    )
};

const TransitionRight = (props: any) => {
    return <Slide {...props} direction="right"/>;
}

export default FeatureFlagsPage;
