import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {EventMessage, EventType, PublicClientApplication} from "@azure/msal-browser";
import {MSAL_CONFIG} from "./constants/auth.constants";
export const msalInstance = new PublicClientApplication(MSAL_CONFIG);

msalInstance.initialize().then(() => {
    // await msalInstance.initialize();
    const [account] = msalInstance.getAllAccounts();
    if (account) {
        msalInstance.setActiveAccount(account);
    }

    msalInstance.addEventCallback((event: EventMessage) => {
        if (
            event.eventType === EventType.LOGIN_SUCCESS &&
            event.payload &&
            "account" in event.payload
        ) {
            if (event.payload?.account) {
                msalInstance.setActiveAccount(event.payload.account);
            }
        }
    });


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App pca={msalInstance} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

});
