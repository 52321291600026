import * as XLSX from "xlsx";
import { MenuItem, Select, Stack, TextField } from "@mui/material";
import { InstallerReportWorkBook } from "../../interfaces/InstallerReportWorkBook";

interface InstallerReportWorkBookSelectorProps {
  installerReportWorkBook: InstallerReportWorkBook;
  onInstallerReportWorkBookChange: (
    installerReportWorkBook: InstallerReportWorkBook
  ) => void;
}

const InstallerReportWorkBookSelector = (
  props: InstallerReportWorkBookSelectorProps
) => {
  const { installerReportWorkBook, onInstallerReportWorkBookChange } = props;
  const {
    workbook,
    worksheetNames,
    workSheetColumns,
  } = { ...installerReportWorkBook };

  const uploadFile = (event: any) => {
    let file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      if (evt.target === null) throw Error("Something went wrong!");
      const binaryString = evt.target.result;
      if (!!binaryString) {
        const workbook = XLSX.read(binaryString, { type: "binary" });
        if (!!workbook) {
          onInstallerReportWorkBookChange({
            ...installerReportWorkBook,
            workbook: workbook,
            worksheetNames: workbook.SheetNames,
            fileName: file.name,
            uploadedFile: file,
          });
        }
      }
    };
    reader.readAsBinaryString(file);
  };

  const onWorksheetNameChange = (event: any) => {
    const workSheetName = event.target.value;
    if (!!workbook && !!workSheetName) {
      const workSheet = workbook.Sheets[workSheetName];
      const [columns]: string[][] = XLSX.utils.sheet_to_json(workSheet, {
        header: 1,
      });
      onInstallerReportWorkBookChange({
        ...installerReportWorkBook,
        workSheet: workSheet,
        selectedWorkSheetName: workSheetName,
        workSheetColumns: columns,
      });
    }
  };

  const onWorksheetColumnChange = (event: any) => {
    const columnName: number = event.target.value;
    if (!!columnName) {
      onInstallerReportWorkBookChange({
        ...installerReportWorkBook,
        selectedWorkSheetIndex: columnName,
      });
    }
  };

  return (
    <Stack spacing={2}>
      <TextField type="file" onChange={uploadFile} />
      <Select
        id="worksheetNames"
        name="worksheetNames"
        onChange={onWorksheetNameChange}
        defaultValue="nothingSelected"
        disabled={!(!!worksheetNames && worksheetNames.length > 0)}
      >
        <MenuItem disabled={true} value="nothingSelected">
          Select a sheet name
        </MenuItem>
        {worksheetNames?.map((worksheetName) => (
          <MenuItem value={worksheetName} key={worksheetName}>
            {worksheetName}
          </MenuItem>
        ))}
      </Select>
      <Select
        id="workSheetColumns"
        name="workSheetColumns"
        onChange={onWorksheetColumnChange}
        defaultValue="nothingSelected"
        disabled={!(!!workSheetColumns && workSheetColumns.length > 0)}
      >
        <MenuItem disabled={true} value="nothingSelected">
          Select the zip code column
        </MenuItem>
        {workSheetColumns?.map((workSheetColumn, index) => (
          <MenuItem value={index} key={workSheetColumn}>
            {workSheetColumn}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default InstallerReportWorkBookSelector;
