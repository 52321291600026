import React, {useState} from 'react';
import Box from "@mui/material/Box";
import {Collapse, Drawer, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FlagIcon from "@mui/icons-material/Flag";
import {useNavigate} from "react-router-dom";
import {ExpandLess, ExpandMore, Summarize} from "@mui/icons-material";

interface LeftNavDrawerProps {
    isDrawerOpen: boolean;
    closeDrawer: () => void;
}

const leftNavMenuItems = [
    {
        path: "/mock-orders",
        label: "Mock Order System",
        icon: <ShoppingCartIcon/>,
        isSubList: false
    },
    {
        path: "/feature-flags",
        label: "Feature Flags",
        icon: <FlagIcon/>,
        isSubList: false
    },
    {
        label: "Reports",
        icon: <Summarize/>,
        isSubList: true,
        subList: [
            {
                path: "/installer-distance-report-all",
                label: "Installer Distance Report - All",
            },
            {
                path: "/installer-distance-report-custom",
                label: "Installer Distance Report - Custom",
            }
        ]
    }
];

const LeftNavDrawer = ({isDrawerOpen, closeDrawer}: LeftNavDrawerProps) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(false);

    const handleClick = () => setOpen(!open);

    const onListItemClick = (path: string) => {
        navigate(path);
        closeDrawer();
    }


    const getMenuItem = (obj: any) => {
        if (obj.isSubList && !!obj.subList && obj.subList.length > 0) {
            return <>
                <ListItemButton onClick={handleClick}>
                    <ListItemIcon>
                        {obj.icon}
                    </ListItemIcon>
                    <ListItemText primary={obj.label}/>
                    {open ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {obj.subList.map((subListItem: any) =>
                            getMenuItem(subListItem)
                        )}
                    </List>
                </Collapse>
            </>
        } else {
            return <ListItemButton
                onClick={() => onListItemClick(obj.path)}
                key={obj.path}>
                <ListItemIcon>
                    {obj.icon}
                </ListItemIcon>
                <ListItemText primary={obj.label}/>
            </ListItemButton>
        }
    }

    return (
        <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={closeDrawer}
        >
            <Box
                sx={{width: 250}}
                role="presentation"
                onKeyDown={closeDrawer}
            >
                <List>
                    {leftNavMenuItems.map((obj) =>
                        getMenuItem(obj)
                    )}
                </List>
            </Box>
        </Drawer>
    );
};

export default LeftNavDrawer;