import React from 'react';
import Box from "@mui/material/Box";
import {AppBar} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";

interface NavBarProps {
    openDrawer: () => void;
}

const NavBar = ({openDrawer}: NavBarProps) => {
    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{mr: 2}}
                        onClick={openDrawer}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                       Treadsy Portal
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default NavBar;