import Authenticator from "../authenticator/authenticator";
import JsonRestApiClient from "../jsonRestApiClient";
import { FeatureFlag } from "../../pages/FeatureFlagsPage/FeatureFlagsPage";

export default class TsyUtilFunctionService {
  private readonly api: JsonRestApiClient;

  constructor(
    private readonly baseUrl: string,
    private readonly apiKey: string,
    private readonly authenticator?: Authenticator
  ) {
    this.api = new JsonRestApiClient(
      `${this.baseUrl}/api`,
      this.authenticator
    );
  }


  readonly fetchFeatureFlags = async () => {
    const uri = `/feature-flags?code=${this.apiKey}`;

    try {
      return await this.api.get<FeatureFlag[]>(uri);
    } catch (err: any) {
      throw (err && err?.message) || "placeholder error handler";
    }
  };

  readonly toggleFeatureFlag = async (key: string, newValue: boolean) => {
    const uri = `/feature-flags?code=${this.apiKey}`;

    try {
      return await this.api.put<FeatureFlag[], {
        "featureFlagName": string,
        "value": boolean
      }>(uri, {
        "featureFlagName": key,
        "value": newValue
      });
    } catch (err: any) {
      throw (err && err?.message) || "placeholder error handler";
    }
  }

  readonly deleteFeatureFlag = async (key: string, value: boolean) => {
    const uri = `/feature-flags?code=${this.apiKey}`;

    try {
      return await this.api.delete<FeatureFlag[]>(uri, {
        "featureFlagName": key,
        "value": value
      });
    } catch (err: any) {
      throw (err && err?.message) || "placeholder error handler";
    }
  }

  readonly createFeatureFlag = async (featureFlagName: string) => {
    const uri = `/feature-flags?code=${this.apiKey}`;

    try {
      return await this.api.post<FeatureFlag[], {
        "featureFlagName": string,
        "value": boolean
      }>(uri, {
        "featureFlagName": featureFlagName,
        "value": false
      });
    } catch (err: any) {
      throw (err && err?.message) || "placeholder error handler";
    }
  }

  readonly publishOrderEvent = async (purchaseOrderNumber: string, topicName: string) => {
    const uri = `/publishOrderEvent?purchaseOrderNumber=${purchaseOrderNumber}&topicName=${topicName}&code=${this.apiKey}`;

    try {
      return await this.api.get<void>(uri);
    } catch (err: any) {
      throw (err && err?.message) || "placeholder error handler";
    }
  }
}
