import TsyUtilFunctionService from "./utilFunctions/tsyUtilFunctionService";
import removeTrailingSlash from "../utils/stringUtils";
import {AXIS_TOKEN_REQUEST} from "../constants/auth.constants";
import {getMsalAuthenticator} from "./authenticator/authenticator";
import AxisFunctionsService from "./axisFunctions";

export default class Api {
    utilFunctions: TsyUtilFunctionService;
    axisFunctions: AxisFunctionsService;

    constructor(tsyUtilFunctionUrl: string, tsyUtilFunctionApiKey: string, axisServiceUrl: string) {

        this.utilFunctions = new TsyUtilFunctionService(
            removeTrailingSlash(tsyUtilFunctionUrl),
            tsyUtilFunctionApiKey,
            getMsalAuthenticator(AXIS_TOKEN_REQUEST)
        );
        this.axisFunctions = new AxisFunctionsService(
            removeTrailingSlash(axisServiceUrl),
            getMsalAuthenticator(AXIS_TOKEN_REQUEST)
        );
    }
}