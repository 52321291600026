import React, {useEffect, useState} from 'react';
import NavBar from "./NavBar";
import LeftNavDrawer from "./LeftNavDrawer";
import AuthGate from "./AuthGate";
import {useMsalAuthentication} from "@azure/msal-react";
import {LOGIN_REQUEST, REQUIRES_AUTH} from "../constants/auth.constants";
import {InteractionRequiredAuthError, InteractionType} from "@azure/msal-browser";

const PageContainer = ({component}: any) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

    const {login, error} = useMsalAuthentication(
        REQUIRES_AUTH ? InteractionType.Silent : InteractionType.None,
        LOGIN_REQUEST
    );

    useEffect(() => {
        if (REQUIRES_AUTH && error instanceof InteractionRequiredAuthError) {
            login(InteractionType.Redirect, LOGIN_REQUEST);
        }
    }, [error, login]);


    const closeDrawer = () => setIsDrawerOpen(false);
    const openDrawer = () => setIsDrawerOpen(true);

    return (
        <AuthGate>
                <NavBar openDrawer={openDrawer}/>
                <LeftNavDrawer isDrawerOpen={isDrawerOpen} closeDrawer={closeDrawer}/>
                {component}
        </AuthGate>
    );
};

export default PageContainer;