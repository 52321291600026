import Authenticator from "../authenticator/authenticator";

export default class JsonRestApiClient {
  private readonly defaultHeaders: HeadersInit | undefined = {
    "Content-Type": "application/json",
  };

  constructor(
    private readonly baseUrl: string,
    // if token is provided then authenticator will be ignored
    private readonly authenticator?: Authenticator
  ) {}

  async get<TResponse>(path: string): Promise<TResponse> {
    const headers = await this.getHeaders();

    const response = await fetch(`${this.baseUrl}${path}`, {
      method: "GET",
      headers,
    });

    const data = await response.text();

    return data ? JSON.parse(data) : {};
  }

  async post<TResponse, TBody extends {}>(
      path: string,
      body: TBody
  ): Promise<TResponse> {
    return await this.postWithHeaders(path, body, {});
  }

  async postFormData(
      path: string,
      formData: FormData
  ) {
    const authHeaders = await this.getAuthHeaders();
    const headers = {
      ...authHeaders,
      ...{},
    };
    const response = await fetch(`${this.baseUrl}${path}`, {
      method: "POST",
      body: formData,
      headers: {
        ...headers,
      },
    });
    if(!response.ok) {
      throw Error(`Exception while calling url: ${this.baseUrl}${path}`);
    }
  }

  async postWithHeaders<TResponse, TBody extends {}>(
    path: string,
    body: TBody,
    passedHeaders: object
  ): Promise<TResponse> {
    const defaultHeaders = await this.getHeaders();
    const headers = {...defaultHeaders, ...passedHeaders};
    const response = await fetch(`${this.baseUrl}${path}`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        ...headers,
      },
    });

    return await response.json();
  }

  async patch<TResponse, TBody extends {}>(
    path: string,
    body: TBody
  ): Promise<TResponse> {
    const headers = await this.getHeaders();
    const response = await fetch(`${this.baseUrl}${path}`, {
      method: "PATCH",
      body: JSON.stringify(body),
      headers,
    });

    return await response.json();
  }

  async put<TResponse, TBody extends {}>(
    path: string,
    body: TBody
  ): Promise<TResponse> {
    const headers = await this.getHeaders();
    const response = await fetch(`${this.baseUrl}${path}`, {
      method: "PUT",
      body: JSON.stringify(body),
      headers,
    });

    return await response.json();
  }

  async delete<TResponse>(path: string, body?: any): Promise<TResponse> {
    const headers = await this.getHeaders();
    const response = await fetch(`${this.baseUrl}${path}`, {
      method: "DELETE",
      body: body ? JSON.stringify(body) : null,
      headers,
    });

    return await response.json();
  }

  private async getHeaders() {
    return {
      ...this.defaultHeaders,
      ...await this.getAuthHeaders()
    };
  }

  private async getAuthHeaders() {
    const authHeader = this.authenticator?.authHeader();
    return {
      ...(authHeader
          ? { [authHeader]: await this.authenticator?.authenticate() }
          : {}),
    };
  }
}
